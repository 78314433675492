import React, {Component} from 'react';
import {Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from 'reactstrap';
import {Link} from 'react-router-dom';
import './NavMenu.css';
import {LoginMenu} from "./Accounting/LoginMenu";

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
                <Navbar id="Navbar"
                        className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
                        container light>
                    <NavbarBrand tag={Link} className="text-light" to="/"><img className="Logo"
                                                        src="/IMGAGES/Phoenix-6.png"/> BlackPhoenix-Services</NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2"/>
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <NavLink tag={Link} className={`${window.location.pathname === '/' ? 'activeLink' : 'text-light'}`}  to="/">Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className={`${window.location.pathname === '/counter' ? 'activeLink' : 'text-light '}`} to="/counter">Counter</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className={`${window.location.pathname === '/fetch-data' ? 'activeLink' : 'text-light '}`} to="/fetch-data">Fetch data</NavLink>
                            </NavItem>
                            <LoginMenu>

                            </LoginMenu>
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}
