import React, {Component} from 'react';
import "./Register.css";
import "../Styles/generell.css";

function onClickRegister() {

}

export class Register extends Component {
    componentDidMount() {
        document.title = "Register | BlackPhoenix-Services";
    }


    render() {
        return (
            <div className="register-box">
                <h2>Register</h2>
                <form>
                    <div className="user-box">
                        <input type="text" id="username" name="" required=""/>
                        <label>Username</label>
                    </div>
                    <div className="user-box">
                        <input type="password" id="password" name="" required=""/>
                        <label>Password</label>
                    </div>

                    <button className="Btn-SUB" onClick={onClickRegister}>
                        <span className="Btn-SUB-span"></span>
                        <span className="Btn-SUB-span"></span>
                        <span className="Btn-SUB-span"></span>
                        <span className="Btn-SUB-span"></span>
                        Submit
                    </button>
                </form>
            </div>
        );
    }
}