import React, {Component} from 'react';
import "./Login.css";
import {Navigate} from 'react-router';

export class Login extends Component {
    state = {
        username: '',
        password: '',
        loggedIn: false,
        Exited: false
    };

    componentDidMount() {
        document.title = "Login | BlackPhoenix-Services";
        document.getElementById('Navbar').hidden = true;
    }


    onClicklogin = () => {
        var username = document.getElementById('username').value;
        var password = document.getElementById('password').value;
    }

    onClickexitbtn = () => {
        document.getElementById('Navbar').hidden = false;
        this.finishedbool = true;
        this.setState({Exited: true});
    }


    render() {
        return (
            <div>
                <div className="Background">
                    <div className="Background-Overlay">
                        
                    </div>
                </div>
                <div className="login-box">
                    <h2>Login</h2>
                    <button className="btn-exit" onClick={this.onClickexitbtn}>
                        <i className="bi bi-x-circle" ></i>
                    </button>
                    {this.state.Exited && (<Navigate to={'/'}/>)}
                    <form>
                        <div className="user-box">
                            <input type="text" id="username" name="" required=""/>
                            <label>Username</label>
                        </div>
                        <div className="user-box">
                            <input type="password" id="password" name="" required=""/>
                            <label>Password</label>
                        </div>
                        <button className="Btn-SUB" onClick={this.onClicklogin}>
                            <span className="Btn-SUB-span"></span>
                            <span className="Btn-SUB-span"></span>
                            <span className="Btn-SUB-span"></span>
                            <span className="Btn-SUB-span"></span>
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

