import ApiAuthorzationRoutes from './components/Accounting/ApiAuthorizationRoutes';
import {Counter} from "./components/Counter";
import {FetchData} from "./components/FetchData";
import {Home} from "./components/Home";
import {Error} from "./components/Error";

const AppRoutes = [
    {
        index: true,
        element: <Home/>
    },
    {
        path: '/counter',
        element: <Counter/>
    },
    {
        path: '/fetch-data',
        element: <FetchData/>
    },
    ...ApiAuthorzationRoutes,
    {
        path: '*',
        element: <Error />
    }
];

export default AppRoutes;
