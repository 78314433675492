import React, {Component} from "react";
import './Styles/404.css';


export class Error extends Component{
    componentDidMount(){
        document.title = "Error 404";
        document.getElementById('Navbar').hidden = true;
    }
    
    render(){
        return(
            <div className="Error">
                <h1 className="ERR-HEAD">404</h1>
                <p className="para1">Oops! Something is wrong.</p>
                <a className="button" href=""><i className="icon-home"></i> Go back in initial page, is better.</a>
            </div>
        );
    }
}